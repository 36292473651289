@font-face {
font-family: '__pretendard_938ff3';
src: url(https://assets.triple.guide/applications/cbt/849d33/_next/static/media/119cf01b445a4dc3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__pretendard_938ff3';
src: url(https://assets.triple.guide/applications/cbt/849d33/_next/static/media/e00e15f44d7b58c0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__pretendard_Fallback_938ff3';src: local("Arial");ascent-override: 93.76%;descent-override: 23.75%;line-gap-override: 0.00%;size-adjust: 101.55%
}.__className_938ff3 {font-family: '__pretendard_938ff3', '__pretendard_Fallback_938ff3'
}

h1.markdown-element {
  color: #666;
  font-weight: bold;
  font-size: 20px;
  margin: 30px 20px 0;
}

h2.markdown-element {
  color: #666;
  font-weight: bold;
  font-size: 16px;
  margin: 16px 20px 0;
}

h3.markdown-element {
  color: #666;
  font-weight: bold;
  font-size: 14px;
  margin: 16px 20px 0;
}

p.markdown-element {
  font-size: 12px;
  line-height: 20px;
  color: #666;
  margin: 14px 20px;
}

li.markdown-element {
  font-size: 12px;
  margin-top: 3px;
}

li.markdown-element::marker {
  content: counter(list + 13);
}

li.markdown-element table {
  margin: 5px 0;
}

ol.markdown-element {
  list-style-type: decimal;
}

ul.markdown-element {
  list-style-type: disc;
}

ol.markdown-element,
ul.markdown-element {
  margin: 10px 20px;
  padding-left: 15px;
  font-size: 14px;
  line-height: 20px;
  color: #666;
}

ol.markdown-element li > ol,
ol.markdown-element li > ul,
ul.markdown-element li > ol,
ul.markdown-element li > ul {
  margin: 6px 0;
  padding-left: 0;
}

ol.markdown-element li > ol,
ul.markdown-element li > ol {
  counter-reset: list;
}

ol.markdown-element li li,
ul.markdown-element li li {
  margin-left: 15px;
}

ol.markdown-element p,
ul.markdown-element p {
  margin: 3px 0 0 0;
}

table.markdown-element {
  margin: 5px 20px;
  font-size: 12px;
  line-height: 20px;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
  width: calc(100% - 40px);
}

table.markdown-element th.markdown-element {
  text-align: left;
  vertical-align: top;
  padding: 10px;
  font-weight: normal;
  background-color: #efefef;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}

table.markdown-element td.markdown-element {
  text-align: left;
  vertical-align: top;
  padding: 10px;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
}

table.markdown-element + table.markdown-element {
  margin-top: 16px;
}

hr.markdown-element {
  margin: 15px;
  border-bottom: 1px solid #ececec;
}

